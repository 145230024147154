<template>
  <div>
    <Header/>
    <b-container
      style="max-width:100%; padding-top:70px; "
    >
    
      <b-row>
        <b-col>
          <h3>内観撮影管理</h3>
        </b-col>
      </b-row>

      <b-row 
        class="border"
        style="font-size:14px"
      >

        <b-row 
        >
          <b-col 
            lg="3"
            md="3"
            cols="5"
          >
            <b-row>
              <label 
                for="companyName"
                class="col-lg-2 col-md-3 cols-2 col-form-label w-auto"
              >
                売主:
              </label>
              <b-col >
                <b-form-input
                  size="sm"
                  id="companyName"
                  type="text"
                  v-model="search.companyName"
                />
              </b-col>
            </b-row>
          </b-col>
            
          <b-col 
            lg="2"
            md="3"
            cols="5"
          >
            <b-row>
              <label 
                for="searchLocationID"
                class="col-lg-2 col-md-3 col-form-label w-auto"
              >
                現場ID:
              </label>
              <b-col>
                <b-form-input
                  size="sm"
                  id="searchLocationID"
                  type="text"
                  v-model="search.locationID"
                />
              </b-col>
            </b-row>
          </b-col>
          
          <b-col 
            lg="2"
            md="3"
            cols="5"
          >
            <b-row>
              <label 
                for="searchPropertyID"
                class="col-lg-2 col-md-3 col-form-label w-auto"
              >
                物件ID:
              </label>
              <b-col>
                <b-form-input
                  size="sm"
                  id="searchPropertyID"
                  type="text"
                  v-model="search.estateID"
                />
              </b-col>
            </b-row>
          </b-col>
            
          <b-col 
            lg="3"
            md="3"
            cols="7"
          >
            <b-row>
              <label
                  class="col-md-3 col-form-label w-auto"
                  for="searchUser"
                >
                  担当者:
                </label>
              <b-col
              >
              <select 
                class="form-select mx-auto"
                id="searchUser"
                v-model="search.user"
                >
                  <option 
                    value=""
                  >
                    全ユーザー
                  </option>
                  <option 
                    v-for="user in usersData" 
                    :key="'user' + user.inUserID"
                    :value="user.inUserID"
                  >
                    {{ user.vcUserLName }}
                  </option>
                </select>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row 
        >
          <b-col 
            cols="12"
            md="6"
          >
            <b-row>
              <label 
                for="searchPropertyName"
                class="col-lg-3 col-md-3 col-form-label w-auto"
              >
                マンション名<br>/物件名:
              </label>
            
              <b-col>
                <b-form-input
                  size="sm"
                  id="searchPropertyName"
                  type="text"
                  v-model="search.estateName"
                />
              </b-col>
            </b-row>
          </b-col>

          <b-col 
            cols="12"
            md="6"
          >
            <b-row>
              <label 
                for="address"
                class="col-lg-1 col-md-3 col-form-label w-auto"
              >
                住所:
              </label>
              <b-col>
                <b-form-input
                  size="sm"
                  id="address"
                  type="text"
                  v-model="search.address"
                />
              </b-col>
            </b-row>
          </b-col>
          
        </b-row>


        <b-row 
        >
          <b-col 
            lg="6"
            md="6"
            cols="12"
          >
            <b-row>
              <label 
                for="searchPropertyName"
                class="col-md-5 col-form-label w-auto"
              >
                フリーワード:
              </label>
            <b-col >
              <b-form-input
                size="sm"
                id="searchPropertyName"
                type="text"
                v-model="search.freeWord"
              />
              </b-col>
            </b-row>
          </b-col>

          <b-col 
            lg="6"
            md="6"
            cols="12"
          >
            <dl 
              class="row"
            >
              <dd 
                class="w-auto col-md-2 col-sm-2"
                >
                種別:
              </dd>
              <dd
                class="col-md-10 col-lg-10 col-sm-10"
              >
                <div
                  v-for="estateKind in estateKinds" 
                  :key="estateKind.id"
                  class="text-nowrap form-check-inline"
                >
                  <input
                    class="form-check-input" 
                    type="checkbox"  
                    :id="estateKind.id" 
                    :value="estateKind.id"
                    v-model="search.estateKinds"
                  >
                  <label 
                    class="form-check-label" 
                    :for="estateKind.id" 
                  >
                    {{ estateKind.value }}
                  </label>  
                </div>
              </dd>
            </dl>
          </b-col>
        </b-row>

        <b-row>
          <b-col 
            md="6"
            cols="12"
          >
            <b-row>
              <label 
                for="shootPhoto"
                class="col col-md-1 cols-1 col-form-label w-auto"
                
              >
                撮影予定日:
              </label>
              <b-col 
                cols="5"
                md="5"
                class="form-floating"
              >
                <b-form-input 
                  for="shootPhoto"
                  type="date"   
                  v-model="search.searchFrom"
                  id="shootFrom"
                /> 
                <label 
                  for="shootFrom"
                >
                  from
                </label>
              </b-col>
              <b-col 
                cols="5"
                md="5"
                class="form-floating"
              >
                <b-form-input 
                  type="date" 
                  v-model="search.searchTo"
                  id="shootTo"
                />
                <label 
                  for="shootTo"
                >
                  to
                </label>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            md="4"
            cols="8"
          >
            <dl 
              class="row"
            >
              <dd 
                class="w-auto col-md-1 col-sm-1"
              >
                内観撮影許可状況:
              </dd>
              <dd 
                class="col-md-auto col-sm-auto"
              >
                <div
                  v-for="intro in introFlagValue" 
                  :key="intro.id"
                  class="text-nowrap form-check-inline"
                >
                  <input
                    class="form-check-input" 
                    type="checkbox"  
                    :id="intro.id" 
                    :value="intro.id"
                    v-model="search.introFlag"
                  >
                  <label 
                    class="form-check-label" 
                    :for="intro.id" 
                  >
                    {{ intro.value }}
                  </label>  
                </div>
              </dd>
            </dl>
          </b-col>
          <b-col 
            md="2"
            sm="4"
          >
            <b-button 
              style="width:50%;"
              variant="success"
              @click="searchList(search)"
            >
              絞り込み
            </b-button>
          </b-col>
        </b-row>
        
      </b-row>  
      <b-row 
        class="mb-1 mt-1 info-area"
      >
        <b-col 
          md="3"
          cols="4"
        >
          <b-button
            variant="primary"
            @click="putIntrospectionModal"
          >
            情報更新
          </b-button>
        </b-col>

        <b-col 
          class="text-center "
          md="5"
          cols="5"
        >
          <b>
            表示件数：{{ totalIntrospection }}件中{{ displayIntrospection }}件
          </b>
          <paginate
            :page-count="getPaginateCount"
            :prev-text="'<'"
            :next-text="'>'"
            :click-handler="paginateClickCallback"
            :container-class="'pagination justify-content-center'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
            :first-last-button="true"
            :first-button-text="'<<'"
            :last-button-text="'>>'"
            v-model="currentPage"
          />
        </b-col>
        <b-col
          md="3"
          lg="3"
          cols="4"
          class="w-auto"
        >
          <select 
            class="form-select " 
            name="displayLimits" 
            v-model="display"
          >
            <option 
              v-for="displayLimit in displayLimits" 
              :key="'displayLimit-' +displayLimit.id"
              :value="displayLimit.value"
            >
              {{ displayLimit.text }}
            </option>
          </select>
        </b-col>
        <b-col
          md="2"
          lg="2"
          cols="3"
        >
          <b-button 
            variant="primary"
            @click="changeLimit"
          >
            表示件数変更
          </b-button>
        </b-col>
      </b-row>
      <span 
        class="text-danger" 
        v-if="message.search !== ''"
      >
        {{ message.search }}
      </span>
      <b-overlay 
        :show="showLoader" 
        z-index="10"
      >
        <b-table-simple 
          class="table table_sticky " 
          style="font-size:13px; max-width:100%;"
          responsive
          bordered
          id="tabletop"
        >
          <b-thead 
            class="table-secondary" 
            head-variant="secondary"
          >
            <b-tr 
              class="thead1"
            >
              
              <b-th

                width="1%"
                scope="col"
              >
                <input 
                  name="allCheck"  
                  class="form-check-input" 
                  type="checkbox"
                  @click="selectAllIntrospection"
                  :checked="isIntrospectionAllSelect"
                >
              </b-th>
              <b-th 
                scope="col" 
                class="text-center"
              >
                物件登録日
              </b-th>
              <b-th 
                width="10%"
                scope="col"
                class="text-center"
              >
                売主/売主への連絡
              </b-th>
              <b-th 
                width="10%"
                scope="col"
                class="text-center"
              >
                現場ID
              </b-th>
              <b-th 
              
                scope="col"
                class="text-center"
              >
                物件ID
              </b-th>
              <b-th
                width="15%"
                scope="col"
                class="text-center text-nowrap"
              >
                マンション名/
                <br>
                物件名
              </b-th>
              <b-th 
                width="15%"
                scope="col"
                class="text-center"
              >
                号室/区画番号
              </b-th>
              <b-th 
                width="15%"
                scope="col"
                class="text-center"
              >
                住所
              </b-th>
              <b-th
                width="15%"
                scope="col"
                class="text-center"
              >
                種別
                <br>
                建築工程
              </b-th>
              
            </b-tr>
            <b-tr 
              class="thead2"
            >
              <b-th></b-th>
              <b-th 
                class="text-nowrap text-center"
              >
                リフォーム完了時期
                <br>
                <b-icon 
                  :variant="sort[0].dtReformasc ? 'primary' : 'secondary'"
                  @click="sortIntrospection('dtReform','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="table_link"
                ></b-icon>
                <b-icon 
                  :variant="sort[1].dtReformdesc ? 'primary' : 'secondary'"
                  class="ms-2 table_link"
                  @click="sortIntrospection('dtReform','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th 
                class="text-nowrap text-center"
              >
                リフォーム完了日
                <br>
                <b-icon 
                  :variant="sort[2].dtReformDayasc ? 'primary' : 'secondary'"
                  @click="sortIntrospection('dtReformDay','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="table_link"
                ></b-icon>
                <b-icon 
                  :variant="sort[3].dtReformDaydesc ? 'primary' : 'secondary'"
                  class="ms-2 table_link"
                  @click="sortIntrospection('dtReformDay','desc')"
                  :icon="arrowDownIcon"
                  scale="2" 
                ></b-icon>
              </b-th>
              <b-th 
                class="text-nowrap text-center"
              >
                撮影予定日 時間
                <br>
                <b-icon 
                  :variant="sort[4].dtIntroShootingTimeasc ? 'primary' : 'secondary'"
                  @click="sortIntrospection('dtIntroShootingTime','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="table_link"
                ></b-icon>
                <b-icon 
                  :variant="sort[5].dtIntroShootingTimedesc ? 'primary' : 'secondary'"
                  class="ms-2 table_link"
                  @click="sortIntrospection('dtIntroShootingTime','desc')"
                  :icon="arrowDownIcon"
                  scale="2" 
                ></b-icon>
              </b-th>
              <b-th 
                class="text-center"
              >
                内観撮影許可状況
              </b-th>
              <b-th
                class="text-center"
              >
                担当
              </b-th>
              <b-th 
                class="text-center"
              >
                連絡方法など
              </b-th>
              <b-th 
                class="text-center"
              >
                鍵の情報
              </b-th>
              <b-th 
                class="text-center"
              >
                備考
              </b-th>
              
            </b-tr>
          </b-thead>
          
          <b-tbody 
            v-for="(introspectionList, index) in introspectionLists" 
            :key="index"
          >
            <b-tr 
            >
              <b-td>
                <b>
                  {{ index + 1 }}
                </b><br>
                <input 
                  class="form-check-input" 
                  type="checkbox"
                  :value="introspectionList.cEstateID"
                  v-model="checkUpdate"
                >
              </b-td>
              <b-td
                class="text-center"
              >
                {{ introspectionList.dtCreate }}
              </b-td>
              <b-td 
                class="text-center"
              >
                {{ introspectionList.vcCompanyName }}/
                {{ introspectionList.tiApprovedFlag }}
              </b-td>
              <b-td 
                class="text-center"
              >
                {{ introspectionList.cLocationID }}
              </b-td>
              <b-td 
                class="text-center"
              >
                {{ introspectionList.cEstateID }}
              </b-td>
              <b-td 
                class="text-center"
              >
                {{ introspectionList.vcEstateName }}
              </b-td>
              <b-td >
                <b-form-input 
                  class="mx-auto"
                  type="text"
                  v-model="introspectionList.vcFixNumber"
                  trim
                  size="sm"
                  style="width:70px;"
                />
              </b-td>
              <b-td>
                {{ introspectionList.vcAddress }}
              </b-td>
              <b-td 
                class="text-center"
              >
                {{ introspectionList.vcEstateKindName }}
                <br>
                {{ introspectionList.tiMaterialNo }}
              </b-td>
              
            </b-tr>
            <b-tr>
              <b-td></b-td>
              <b-td 
                class="text-center"
              >
                {{ introspectionList.dtReform }}
              </b-td>
              <b-td>
                <b-form-input 
                  type="date"
                  v-model="introspectionList.dtReformDay"
                  style="width:90%;"
                  class="mx-auto"
                />
              </b-td>
              <b-td 
                class="table_link text-center" 
                @click="selectDateTime(introspectionList.dtIntrospectionShootingDate,introspectionList)"
              >
                {{ introspectionList.dtIntrospectionShootingDate }}
              </b-td>
              <b-td>
                <select 
                  class="form-select mx-auto"
                  v-model="introspectionList.tiIntroFlag"
                  style="width:100px;"
                >
                  <option 
                    v-for="intro in introFlagValue" 
                    :key="intro.value"
                    :value="intro.id"
                    
                  >
                    {{ intro.value }}
                  </option>
                </select>
              </b-td>
              <b-td 
                class="text-center table_link"
                @click="showUserModal(introspectionList.cEstateID)"
              >
                {{ introspectionList.vcUserLName }}
              </b-td>
              <b-td>
                <textarea 
                  v-model="introspectionList.txContactAddress"
                  rows="3"
                  class="form-control mx-auto"
                  style="width:170px;"
                />
                
              </b-td>
              <b-td>
                <textarea 
                  v-model="introspectionList.txKeyInformation"
                  rows="3"
                  class="form-control mx-auto"
                  style="width:170px;"
                />
              </b-td>
              <b-td>
                <textarea 
                  v-model="introspectionList.txNote"
                  rows="3"
                  class="form-control mx-auto"
                  style="width:170px;"
                />
              </b-td>
              <b-td></b-td>
            </b-tr>
          </b-tbody>
          
        </b-table-simple>
      </b-overlay> 

      <UpdateUserModal
        :userID="userID"
        :estateID="selectEstateID"
        :checkUpdate="checkUpdate"
        :introspectionLists="introspectionLists"
        :updatePageType="updatePageType"
      />
      <UpdateShootScheduleModal
        :shootDate="selectShootDate"
        :checkUpdate="checkUpdate"
        :introspectionData="selectIntrospectionData"
        :introspectFlag=true
        :userID="userID"
        :introspectionLists="introspectionLists"
      />
      
    </b-container>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import UpdateUserModal from '../../components/UpdateUserModal.vue'
import UpdateShootScheduleModal from '../../components/UpdateShootScheduleModal.vue'
import { Apimixin } from '../../mixins/api'
import {ja} from 'vuejs-datepicker/dist/locale'

export default {
  title: '内観撮影管理',
  components: {
    Header,
    UpdateUserModal,
    UpdateShootScheduleModal,
  },
  mixins:[Apimixin],
  data() {
    return {
      arrowUpIcon:'file-arrow-up-fill',
      arrowDownIcon:'file-arrow-down-fill',
      ja:ja,
      datepickerFormat:'yyyy-MM-dd',
      userID:'',
      search: {
        searchFrom:'',
        searchTo:'',
        estateID:'',
        estateName:'',
        locationID:'',
        companyName:'',
        address:'',
        freeWord:'',
        user:'',
        estateKinds:[],
        progress:[],
        changeContent:'',
        sort:'',
        sortType:'',
        introFlag:[],
      },
      sortColumn:'',
      sortType:'',
      sort: [
        { dtReformasc:false }, 
        { dtReformdesc:false },
        { dtReformDayasc:false },
        { dtReformDaydesc:false },
        { dtIntroShootingTimeasc:false },
        { dtIntroShootingTimedesc:false },
      ],
      estateKinds:[
        { id:2,	value:'戸建て' },
        { id:3,	value:'マンション' },
      ],
      currentPage:1, 
      limit:10,
      offset:0,
      isIntrospectionAllSelect:false,
      introspectionLists:[],
      checkUpdate:[],
      searchOption:false,
      display:'',
      selectEstateID:'',
      message:{
        search:''
      },
      totalIntrospection:'',
      displayIntrospection:'',
      selectShootDate:'',
      selectIntrospectionData:'',
      updatePageType:'',
      isSessionStorageFlag:false
    }
  },
  created() {
    this.userID = sessionStorage.getItem('userId')
    //ユーザーデータ取得
    this.getUsers(this.userID)
    //検索履歴がセッションストレージにあればフラグを立てる
    if(sessionStorage.getItem('introspectionListSearch') !== null) {
      this.isSessionStorageFlag = true
    }
  },
  mounted() {
    this.showLoader = true
    //limit値がセッションストレージにあれば取得
    if(sessionStorage.getItem('introspectionLimit') != null) {
      this.limit = Number(sessionStorage.getItem('introspectionLimit'))
    }
    //ページ数がセッションストレージにあれば取得
    if(sessionStorage.getItem('introspectionCurrentPage') != null) {
      this.currentPage = Number(sessionStorage.getItem('introspectionCurrentPage'))
    }
    this.searchOption = true
    switch(this.isSessionStorageFlag) {
      //セッション格納済み
      case true:
        this.search = JSON.parse(sessionStorage.getItem('introspectionListSearch'))
        this.display = this.limit
        this.getLists();
      break;
      //セッション未格納
      case false:
        for(let i = 0;i < 2;i++) {
          this.search.introFlag.push(this.introFlagValue[i].id)
        }
        for(let i = 0;i < this.estateKinds.length;i++) {
          this.search.estateKinds.push(this.estateKinds[i].id)
        }
        this.getLists()
        this.display = this.limit
      break;
    }
  },
  methods:{
    //担当者更新のモーダルオープン
    showUserModal(estateID) {
      this.updatePageType = 'intro'
      this.selectEstateID = estateID
      this.$bvModal.show('UpdateUserModal')
    },
    //チェックボックス一括チェック
    selectAllIntrospection() {
      if(this.isIntrospectionAllSelect) {
        this.checkUpdate = []
        this.isIntrospectionAllSelect = false
      } else {
        this.checkUpdate = []
        for(let key in this.introspectionLists) {
          this.checkUpdate.push(this.introspectionLists[key].cEstateID)
        }
        this.isIntrospectionAllSelect = true
      }
    },

    //絞り込み
    searchList(searchTheme) {
      if(searchTheme.estateKinds.length !== 0) {
        if(searchTheme.introFlag.length !== 0) {
          if(searchTheme.searchFrom !== '' && searchTheme.searchTo !== '' && 
            searchTheme.searchFrom > searchTheme.searchTo) {
            this.$swal({
              icon: 'error',
              title:'絞込み期間が不正です',
              confirmButtonText:"閉じる"
            })
            return
          } else {
            this.showLoader = true
            this.searchOption = true
            let offset = 0
            this.introspectionLists = []
            sessionStorage.setItem('introspectionListSearch',JSON.stringify(this.search))
            this.searchIntrospectionList(offset,this.limit,searchTheme)
            this.currentPage = 1
          }
          
        } else {
          this.$swal({
            icon: 'error',
            title:'内観撮影の許可状況を選択してください。',
            confirmButtonText:"閉じる"
          })
          return
        }
      } else {
        this.$swal({
          icon: 'error',
          title:'種別を選択してください。',
          confirmButtonText:"閉じる"
        })
        return
      }
    },

    //施設並び替え
    sortIntrospection(sort,sortType) {
      if(this.search.sort == sort &&
        this.search.sortType == sortType) {
        this.search.sort = ''
        this.search.sortType = ''
        let sortFlag = sort + sortType
        this.sort.forEach(elm => {
          Object.keys(elm).forEach(key => {
            if(key === sortFlag) {
              elm[key] = false
            } 
          })
        })
      } else {
        this.search.sort = sort
        this.search.sortType = sortType
        let sortFlag = sort + sortType
        this.sort.forEach(elm => {
          Object.keys(elm).forEach(key => {
            if(key === sortFlag) {
              elm[key] = true
            } else {
              elm[key] = false
            }
          })
        })
      }
      
      this.showLoader = true
      this.introspectionLists = []
      this.searchOption = true
      
      let offset = (this.currentPage - 1) * this.limit;
      this.searchIntrospectionList(offset,this.limit,this.search)
    },
    //ページネーションクリック時現在のページ番号取得
    paginateClickCallback(pageNum) {
      this.currentPage = Number(pageNum);
      this.showLoader = true
      this.introspectionLists = []
      sessionStorage.setItem('introspectionCurrentPage', JSON.stringify(this.currentPage))
      this.introspectionLists = []
      this.$scrollTo('#tabletop');
      this.getLists();
    },
    //表示件数変更
    changeLimit() {
      this.limit = this.display
      this.showLoader = true
      this.introspectionLists = []
      sessionStorage.setItem('introspectionLimit', JSON.stringify(this.limit))
      this.currentPage = 1
      this.getLists()
    },
    //内観撮影情報の更新
    putIntrospectionModal() {
      if(this.checkUpdate.length !== 0) {
        let self =this
        var estateVals = []
        let errorMessage = []
        self.checkUpdate.filter(function(value) {
          for(let i = 0;i < self.introspectionLists.length;i++) {
            if(self.introspectionLists[i].cEstateID === value) {
              estateVals.push({
                introuid:self.introspectionLists[i].inUserID,
                estateID:self.introspectionLists[i].cEstateID,
                fixNumber:self.introspectionLists[i].vcFixNumber,
                reformDay:self.introspectionLists[i].dtReformDay,
                introShootingDate:self.introspectionLists[i].dtIntrospectionShootingDate,
                keyInformation:self.introspectionLists[i].txKeyInformation,
                note:self.introspectionLists[i].txNote,
                cCompanyID:self.introspectionLists[i].cCompanyID,
                contactAddress:self.introspectionLists[i].txContactAddress,
                introFlag:self.introspectionLists[i].tiIntroFlag
              })
            }
          }
        })
        for(let introspectionList of this.introspectionLists) {
          for(let estateVal of estateVals) {
            if(estateVal.estateID == introspectionList.cEstateID ) {
              if(estateVal.reformDay > introspectionList.dtIntrospectionShootingDate ) {
                errorMessage.push(introspectionList.vcEstateName+'の撮影予定日がリフォーム完了日より前に設定されています。'+'<br>') 
              }
            }
          }
        }
        if(errorMessage.length == 0) {
          this.$swal({
            title: '物件情報更新',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '確定',
            cancelButtonText:'キャンセル'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$swal({
                title: '処理中',
                html: '処理終了まで画面はそのままにしてください。', 
                allowOutsideClick : false , 
                showConfirmButton: false, 
                onBeforeOpen: () => {
                  this.$swal.showLoading()
                }
              }); 
              this.putIntrospection(this.userID,'all',estateVals)
            }
          })
        } else {
          this.$swal({
            icon: 'error',
            html:`${errorMessage}`,
            confirmButtonText:"閉じる"
          })
          return
        }
      } else {
        this.$swal({
          icon: 'error',
          title:'物件をチェックボックスで選択してください。',
          confirmButtonText:"閉じる"
        })
      }
    },
    getLists() {
      let offset = (this.currentPage - 1) * this.limit;
      sessionStorage.setItem('introspectionOffset', offset)
      sessionStorage.setItem('introspectionLimit', this.limit)
      if(this.searchOption) {
        return this.searchIntrospectionList(offset,this.limit,this.search)
      } else {
        return this.getIntrospectionList(offset, this.limit);
      }
    },
    //撮影予定日変更
    selectDateTime(shootDate,introspectionData) {
      this.selectIntrospectionData = introspectionData
      this.selectShootDate =shootDate
      this.$bvModal.show('UpdateShootScheduleModal')
    }
  
  },
  computed: {
    //総ページ数の計算
    getPaginateCount() {
      return Math.ceil(this.totalIntrospection / this.limit);
    }
  },
  watch:{
    'checkUpdate':function() {
      if (this.checkUpdate.length !== this.introspectionLists.length) {
        this.isIntrospectionAllSelect = false
      } else {
        this.isIntrospectionAllSelect = true
      }
    },
    'currentPage':function() {
      sessionStorage.setItem('introspectionCurrentPage', this.currentPage)
    }
  
  }

}
</script>
<style scoped>

.info-area {
  position: sticky !important;
  top: 58px;
  z-index:0;
  background-color: white;
}
.table_link {
  cursor: pointer;
}
.table_link:hover {
  background-color:gainsboro;
}
.table_sticky {
  display: block;
  overflow-y: scroll;
  height: calc(70vh);
    
}
.thead1 {
  position: sticky;
  top: 0;
  z-index: 1;
}
.thead2 {
  position: sticky;
  top: 55px;
  z-index: 1;
}



</style>
