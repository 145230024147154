<template>
  <div>
    <b-modal
      id="UpdateShootScheduleModal"
      ok-disabled
      hide-header-close
    >
      <template #modal-header>
        <h5>撮影予定日更新</h5>
        <b-form-checkbox
            id="checkbox-1"
            v-model="checkShootScheduleAllUpdate"
          >
            チェックの入った物件を一括変更
          </b-form-checkbox>
        <b-form-checkbox
            id="checkbox-2"
            v-model="checkLocationIdAllUpdate"
          >
            現場IDが同じものを一括変更
          </b-form-checkbox>
      </template>
      <b-row>
        <datetimepicker
          :format="'YYYY-MM-DD HH:mm'"
          :minute-interval="30"
          label="日時を選択"
          v-model="updateShootDate"
          inline
        ></datetimepicker>
      </b-row>

      <template #modal-footer>
        <b-row>
          <b-col
            md="auto"
            cols="auto"
          >
            <b-button
              v-if="introspectFlag"
              variant="primary"
              @click="putUpdateIntrospect"
            >
              更新
            </b-button>
            <b-button
              v-else
              variant="primary"
              @click="putUpdateShootSchedule"
            >
              更新
            </b-button>
          </b-col>
          <b-col
            md="auto"
            cols="auto"
          >
            <b-button
              @click="hideModal"
            >
              閉じる
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Apimixin } from '../mixins/api'

export default {
  mixins:[Apimixin],
  props:{
    userID:String,
    estateID:String,
    shootDate:String,
    shootingID:String,
    checkUpdate:[],
    introspectionData:{},
    introspectFlag:Boolean,
    introspectionLists:[],
    progressID:Number,
    toDay:String
  },
  data() {
    return {
      updateUserID:'',
      checkShootScheduleAllUpdate:false,
      checkLocationIdAllUpdate:false,
      updateShootDate:''
    }
  },
  mounted() {
    //ユーザー情報取得
    this.getUsers(this.userID)
  },
  methods:{
    //モーダルクローズ
    hideModal() {
      this.$bvModal.hide('UpdateShootScheduleModal')
    },
   //撮影予定日更新
    putUpdateShootSchedule() {
      if(this.updateShootDate > this.toDay || this.progressID == 2) {
        if(!(this.checkShootScheduleAllUpdate && this.checkLocationIdAllUpdate)) {
          let estateVals = []
          if(this.checkShootScheduleAllUpdate && this.checkUpdate.length !== 0) {
            for(let updateShootingID of this.checkUpdate) {
              estateVals.push({
                shootingPrpgressID:Number(updateShootingID),
                shootingDate:this.updateShootDate
              })
            }
          } else {
            estateVals.push({
              shootingPrpgressID:Number(this.shootingID),
              shootingDate:this.updateShootDate
            })
          }
          this.$swal({
            title: '処理中',
            html: '処理終了まで画面はそのままにしてください。',
            allowOutsideClick : false ,
            showConfirmButton: false,
          });
          if(this.checkLocationIdAllUpdate) {
              this.putEstateList(this.userID,estateVals,"",this.estateID)
          } else {
              this.putEstateList(this.userID,estateVals)
          }
        }  else {
          this.$swal({
            icon: 'error',
            html:'チェックの入った物件を一括変更と現場IDが同じものを一括変更は同時に更新できません。',
            confirmButtonText:"閉じる"
          })
          return
        }
      } else {
        this.$swal({
          icon: 'error',
          html:'撮影予定日が本日日付より前で設定されています。',
          confirmButtonText:"閉じる"
        })
        return
      }
    },
    //内観杖委管理画面での更新
    putUpdateIntrospect() {
      var estateVals = []
      let errorMessage = []
      if(this.checkShootScheduleAllUpdate && this.checkUpdate.length != 0) {
        this.checkUpdate.filter((value) => {
          for(let introspectionList of this.introspectionLists) {
            if(introspectionList.cEstateID === value) {
              estateVals.push({
                introuid:introspectionList.inUserID,
                estateID:introspectionList.cEstateID,
                fixNumber:introspectionList.vcFixNumber,
                reformDay:introspectionList.dtReformDay,
                introShootingDate:this.updateShootDate ,
                keyInformation:introspectionList.txKeyInformation,
                note:introspectionList.txNote,
                cCompanyID:introspectionList.cCompanyID,
                contactAddress:introspectionList.txContactAddress,
                introFlag:introspectionList.tiIntroFlag
              })
            }
          }
        })
      }else {
        estateVals.push({
          introuid:this.introspectionData.inUserID,
          estateID:this.introspectionData.cEstateID,
          fixNumber:this.introspectionData.vcFixNumber,
          reformDay:this.introspectionData.dtReformDay,
          introShootingDate:this.updateShootDate ,
          keyInformation:this.introspectionData.txKeyInformation,
          note:this.introspectionData.txNote,
          cCompanyID:this.introspectionData.cCompanyID,
          contactAddress:this.introspectionData.txContactAddress,
          introFlag:this.introspectionData.tiIntroFlag
        })
      }
      for(let introspectionList of this.introspectionLists) {
        for(let estateVal of estateVals) {
          if(introspectionList.cEstateID == estateVal.estateID) {
            if(estateVal.reformDay > estateVal.introShootingDate) {
              errorMessage.push(introspectionList.vcEstateName+'の撮影予定日がリフォーム完了日より前に設定されています。'+'<br>')
            }
          }
        }
      }
      if(errorMessage.length == 0) {
        this.$swal({
          title: '処理中',
          html: '処理終了まで画面はそのままにしてください。',
          allowOutsideClick : false ,
          showConfirmButton: false,
        });
        this.putIntrospection(this.userID,'all',estateVals)
      } else {
        this.$swal({
          icon: 'error',
          html:`${errorMessage}`,
          confirmButtonText:"閉じる"
        })
        return
      }
    }
  },

  watch:{
    'shootDate':function() {
      this.updateShootDate = this.shootDate
    }
  }
}
</script>
<style>
.modal-dialog {
    max-width: 620px;
}
</style>