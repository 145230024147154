<template>
  <div>
    <b-modal 
      id="UpdateUserModal" 
      scrollable 
      ok-disabled
      hide-header-close
      size="md"
    >
    <template #modal-header>
      <b-row>
        <b-col >
          <h5>
            担当更新
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col >
          <b-form-checkbox
            id="checkbox-1"
            v-model="checkUserAllUpdate"
          >
            <span 
              v-if="updatePageType == 'area'"
            >
              チェックの入ったエリアを一括変更
            </span>
            <span 
              v-else
            >
              チェックの入った物件を一括変更
            </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </template>
    <table 
      class="table table-hover "
    >
      <tbody >
        <tr 
          v-for="user in usersData" 
          :key="'user' + user.inUserID"
          style="cursor:pointer; " 
          @click="putIntroUser(user.inUserID,user.vcUserLName)"
        >
          <td 
            class="text-center"
          >
            {{ user.vcUserLName }}
          </td>
        </tr>
      </tbody>
    </table>
    <template #modal-footer>
      <b-row>
        <b-col 
          style="text-align:right;"
        >
          <b-button 
            @click="hideModal"
          >
            閉じる
          </b-button>
        </b-col>
      </b-row>
    </template>
    </b-modal>
  </div>
</template>

<script>
import { Apimixin } from '../mixins/api'
export default {
  mixins:[Apimixin],
  props:{
    userID:String,
    estateID:String,
    shootingID:String,
    shootingType:String,
    checkUpdate:[],
    introspectionLists:[],
    areaUpdateType:String,
    updatePageType:String,
    prefectureCode:String,
    cityCode:String
  },
  data() {
    return {
      updateUserID:'',
      checkUserAllUpdate:false
    }
  },
  mounted() {
    //ユーザー情報取得
    this.getUsers(this.userID)
  },
  methods:{
    //モーダルクローズ
    hideModal() {
      this.$bvModal.hide('UpdateUserModal')
    },
    //担当者のみ更新
    putIntroUser(userID,userName) {
      let estateVals = []
      switch(this.updatePageType) {
        //内観写真管理画面での更新
        case 'intro':
          if(this.checkUserAllUpdate && this.checkUpdate.length !== 0) {
            for(let updateEstateIDs of this.checkUpdate) {
              estateVals.push({
                introuid:userID,
                estateID:updateEstateIDs
              })
            }
          } else {
            estateVals.push({
              introuid:userID,
              estateID:this.estateID
            })
          }
          this.$swal({
            title: '処理中',
            html: '処理終了まで画面はそのままにしてください。', 
            allowOutsideClick : false , 
            showConfirmButton: false, 
          });
          this.putIntrospection(this.userID,'introUser',estateVals,userName)
        break;
        //進捗管理画面での更新
        case 'estate':
          if(this.checkUserAllUpdate && this.checkUpdate.length !== 0) {
            for(let updateShootingID of this.checkUpdate) {
              estateVals.push({
                shootingPrpgressID:updateShootingID,
                shootingID:userID,
              })
            }
          } else {
            estateVals.push({
              shootingPrpgressID:Number(this.shootingID),
              shootingID:Number(userID),
            })
          }
          this.$swal({
            title: '処理中',
            html: '処理終了まで画面はそのままにしてください。', 
            allowOutsideClick : false , 
            showConfirmButton: false, 
          });
          this.putEstateList(this.userID,estateVals,userName)
        break;
        //エリア編集画面での更新
        case 'area':
          if(this.checkUserAllUpdate && this.checkUpdate.length !== 0) {
            for(let updateData of this.checkUpdate) {
              estateVals.push({
                prefectureCd: updateData.prefectureCode,
                cityCd: updateData.cityCode,
                managerUID: userID
              })
            }
          } else {
            estateVals.push({
              prefectureCd: this.prefectureCode,
              cityCd: this.cityCode,
              managerUID: userID
            })
          }
          this.$swal({
            title: '処理中',
            html: '処理終了まで画面はそのままにしてください。', 
            allowOutsideClick : false , 
            showConfirmButton: false, 
          });
          this.putAreaListData(this.userID,estateVals,this.areaUpdateType)
        break;
      }
    }
  },
  watch:{
    'updatePageType':function() {
      this.usersData.unshift({
        inUserID:'',
        vcUserLName:'未割当'
      })
    }
  }
}
</script>
